export default {
  "characters": {
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "delete": {
      "success": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character successfully deleted and noted to ACP"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "idNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Number"])},
    "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character Name"])},
    "playerID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player ID"])},
    "setJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Job"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characters"])}
  },
  "chats": {
    "closedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing Date"])},
    "closedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing Person"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
    "selectChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Char"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])}
  },
  "download": {
    "delete-download-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this download?"])},
    "delete-download-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete download"])},
    "delete-section-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this download section? This will also delete all downloads!"])},
    "delete-section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete download section"])}
  },
  "permissions": {
    "addPermSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission has been added successfully"])},
    "groups": {
      "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new group"])},
      "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the group?"])},
      "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete group"])},
      "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit group"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Permission"])},
    "ranks": {
      "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit rank"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
      "mcPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft - Prefix"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranks"])},
      "titlesingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])}
  },
  "player": {
    "ban": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "create": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create ban"])}
      },
      "delete": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to delete this ban?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete ban"])}
      },
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "edit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit ban"])}
      },
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until"])}
    },
    "char-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible Characters"])},
    "characters": {
      "headers": {
        "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "id-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Number"])},
        "last-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Played"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
        "player-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player ID"])},
        "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working?"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character"])}
    },
    "mod-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First use"])},
    "mod-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ModID"])},
    "mod-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last use"])},
    "mod-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mod Name"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "notification": {
      "error": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This player is probably not online."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
      },
      "success": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The player was notified successfully"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "timeout": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connection to MC Server possible."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])}
      }
    },
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "warnings": {
      "create": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create warning"])}
      },
      "delete": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to delete this warning?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete warning"])}
      },
      "edit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit warning"])}
      },
      "headers": {
        "proof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "warned-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warned at"])},
        "warner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warned by"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnings"])}
    }
  },
  "players": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player name"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID"])}
  },
  "pre-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration - "])},
  "property": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
    "rentable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For rent"])}
  },
  "unban": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The player isn't banned"])},
    "btn": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete ban"])},
      "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shorten ban"])}
    },
    "success": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully unbanned"])},
      "text-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully shorted"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete ban"])}
  },
  "vehicle": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
    "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "licensePlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Plate"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "vehicleColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Color"])},
    "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Type"])}
  },
  "chat": {
    "welcome": {
      "APPLY_CONTENT_TEXTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Please send us the following information:<\\/strong> <ul class=\"ml-7\"> <li>Your name and age<\\/li> <li>Why you choose StateMC<\\/li> <li>Your experience in the areas of pixeling and textures<\\/li> <\\/ul><br><br>Please send us an example of your work!"])},
      "APPLY_CONTENT_SOCIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Please send us the following information:<\\/strong> <ul class=\"ml-7\"> <li>Your name and age<\\/li> <li>Why you choose StateMC<\\/li> <li>Your experience in the area of social media<\\/li> <\\/ul><br><br>Please send us an example of your work!"])}
    },
    "premessages": {
      "CHAR_DELETE_ACCEPT_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Chardelete (only one Character)"])},
      "CHAR_DELETE_TALK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite to Chardelete talk"])}
    }
  }
}