export default {
  "characters": {
    "setJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "delete": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "idNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
    "playerID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character"])}
  },
  "chats": {
    "closedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "closedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "selectChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "download": {
    "delete-download-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce téléchargement ?"])},
    "delete-download-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le téléchargement"])},
    "delete-section-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cette section de téléchargement ? Cela supprimera également tous les autres téléchargements !"])},
    "delete-section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de la section du téléchargement"])}
  },
  "permissions": {
    "addPermSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission has been added successfully"])},
    "groups": {
      "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau groupe"])},
      "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le groupe?"])},
      "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
      "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Autorisation"])},
    "ranks": {
      "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le grades"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
      "mcPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft - Prefix"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grades"])},
      "titlesingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations"])}
  },
  "player": {
    "char-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possibilité de caractère"])},
    "characters": {
      "headers": {
        "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "id-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
        "last-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernièrement joué"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "player-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player ID"])},
        "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working?"])},
        "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character"])}
    },
    "mod-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première utilisation"])},
    "mod-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ModID"])},
    "mod-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière utilisation"])},
    "mod-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du Mod"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "warnings": {
      "headers": {
        "proof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuve"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])},
        "warned-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Averti à"])},
        "warner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warner"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissements"])}
    },
    "ban": {
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])}
    },
    "notification": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
      },
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    }
  },
  "players": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du joueur"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joueur"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID"])}
  },
  "pre-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration - "])},
  "unban": {
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    }
  },
  "vehicle": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "property": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  }
}