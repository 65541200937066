export default {
  "characters": {
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag"])},
    "delete": {
      "success": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Charakter wurde gelöscht & die Löschung notiert"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])}
      }
    },
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "idNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Nummer"])},
    "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charaktername"])},
    "playerID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler ID"])},
    "setJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job setzen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charaktere"])}
  },
  "chats": {
    "closedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließzeit"])},
    "closedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließer"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
    "selectChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Char auswählen"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absender"])}
  },
  "download": {
    "delete-download-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diesen Download wirklich löschen?"])},
    "delete-download-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download löschen"])},
    "delete-section-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diesen Download-Abschnitt wirklich löschen? Es werden dabei auch alle Downloads gelöscht!"])},
    "delete-section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Abschnitt löschen"])}
  },
  "permissions": {
    "addPermSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung wurde erfolgreich hinzugefügt"])},
    "groups": {
      "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Gruppe erstellen"])},
      "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du die Grupppe wirklich löschen?"])},
      "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe löschen"])},
      "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe bearbeiten"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Berechtigung"])},
    "ranks": {
      "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang bearbeiten"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
      "mcPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft - Prefix"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ränge"])},
      "titlesingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])}
  },
  "player": {
    "ban": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "create": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban anlegen"])}
      },
      "delete": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diesen Ban wirklich löschen?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban löschen"])}
      },
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
      "edit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban bearbeiten"])}
      },
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])}
    },
    "char-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charaktermöglichkeit"])},
    "characters": {
      "headers": {
        "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "id-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Nummer"])},
        "last-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt Gespielt"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler"])},
        "player-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player ID"])},
        "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitet?"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakter"])}
    },
    "mod-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Benutzung"])},
    "mod-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mod ID"])},
    "mod-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Benutzung"])},
    "mod-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mod Name"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
    "notification": {
      "error": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler ist wahrscheinlich nicht online."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
      },
      "success": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler wurde erfolgreich notified"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])}
      },
      "timeout": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Verbindung zum MC Server möglich."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])}
      }
    },
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "warnings": {
      "create": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnung anlegen"])}
      },
      "delete": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Verwarnung wirklich löschen?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnung löschen"])}
      },
      "edit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnung bearbeiten"])}
      },
      "headers": {
        "proof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beweise"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
        "warned-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnt am"])},
        "warner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnt von"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarnungen"])}
    }
  },
  "players": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielername"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID"])}
  },
  "pre-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration - "])},
  "property": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
    "rentable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietbar"])}
  },
  "unban": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler ist nicht gebannt"])},
    "btn": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban löschen"])},
      "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban verkürzen"])}
    },
    "success": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Spieler wurde erfolgreich entbannt"])},
      "text-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ban wurde erfolgreich verkürzt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban aufheben"])}
  },
  "vehicle": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
    "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "licensePlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besitzer"])},
    "vehicleColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugfarbe"])},
    "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modell"])}
  },
  "chat": {
    "welcome": {
      "APPLY_CONTENT_SOCIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Bitte geb uns noch folgende Informationen:<\\/strong> <ul class=\"ml-7\"> <li>Dein Name und Alter<\\/li> <li>Warum StateMC<\\/li> <li>Deine Erfahrung im Bereich Social-Media<\\/li> <\\/ul><br><br>Bitte lass uns zur besseren Einschätzung einige Exemplare deiner Werke zukommen!"])},
      "APPLY_CONTENT_TEXTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Bitte geb uns noch folgende Informationen:<\\/strong> <ul class=\"ml-7\"> <li>Dein Name und Alter<\\/li> <li>Warum StateMC<\\/li> <li>Deine Erfahrung im Bereich Pixeln und Texturen<\\/li> <\\/ul><br><br>Bitte lass uns zur besseren Einschätzung einige Exemplare deiner Werke zukommen!"])}
    },
    "premessages": {
      "CHAR_DELETE_ACCEPT_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charlöschung akzeptieren (nur ein Charakter)"])},
      "CHAR_DELETE_TALK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charlöschung zum Gespräch einladen"])}
    }
  }
}