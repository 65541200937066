<template>
  <v-container class="mb-5">
    <v-row justify="center" class="mb-4">
      <v-col
          v-for="(social, index) in socials"
          :key="index"
          class="pa-2"
          cols="auto"
      >
        <a :href="social.url" target="_blank" rel="noopener noreferrer">
          <v-card color="primary" style="width: fit-content;" class="pa-2 rounded-xl">
            <v-img :src="social.image" :alt="social.text" :height="30" :width="30" />
          </v-card>
        </a>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col cols="12" class="d-flex flex-column flex-md-row justify-center mb-4">
        <v-btn
            v-for="(link, i) in links"
            :key="i"
            color="primary"
            variant="text"
            class="my-2 mx-md-2"
            rounded="xl"
            :to="link.url"
        >
          {{ $t(link.text) }}
        </v-btn>
      </v-col>

      <v-col class="text-center mt-4" cols="12">
        {{ $t('mojangwarn.title') }}
      </v-col>

      <v-col class="text-center mt-4" cols="12">
        COPYRIGHT © {{ new Date().getFullYear() }} — <strong><span class="color-primary">State</span>MC</strong>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { Utils } from '@/modules/utils';

const socials = [
  {
    text: 'Discord',
    url: 'https://discord.com/invite/JNVkRxP',
    image: Utils.getImgUrl('social/discord.svg'),
  },
  {
    text: 'YouTube',
    url: 'https://www.youtube.com/channel/UCz6W7EFoaAJJ55CCWJRzsIg',
    image: Utils.getImgUrl('social/youtube.svg'),
  },
  {
    text: 'Teamspeak',
    url: 'ts3server://ts.statemc.de',
    image: Utils.getImgUrl('social/teamspeak.svg'),
  },
  {
    text: 'TikTok',
    url: 'https://www.tiktok.com/@statemc_de',
    image: Utils.getImgUrl('social/tiktok.svg'),
  },
  {
    text: 'Instagram',
    url: 'https://www.instagram.com/statemc.de/',
    image: Utils.getImgUrl('social/instagram.svg'),
  },
];

const links = [
  {
    text: 'imprint.title',
    url: '/imprint',
  },
  {
    text: 'policy.title',
    url: '/policy',
  },
];
</script>

<style scoped>

</style>
